<template>
    
    <div class="page overflow-hidden">
        <div class="flex items-center justify-center h-screen overflow-hidden">
                <h1 class="mb-10 text-5xl font-extrabold">Projects</h1>
                <slot />
            </div>

  </div>
</template>
